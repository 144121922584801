import React from "react";
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLocationDot,
  FaWhatsapp,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { NavLink } from "react-router-dom";

const Footer = ({ data, address ,tag,product}) => {
 
  return (
    <footer className="bg-[#000214] text-white  ">
       <div
        dangerouslySetInnerHTML={{
          __html: tag?.footer_tag,
        }}
      />
      <div className="flex flex-col w-[85%] mx-auto py-6 md:flex-row justify-between items-center  ">
        <div className="md:w-[55%]">
          <h1 className="text-2xl     ">{data.common_cta_title}</h1>
        </div>
        <NavLink to={data.common_cta_button_url}>
          <span className="border rounded-md  text-base border-white px-6 py-2 mt-4 md:mt-0  flex items-center transition-colors duration-300 ease-in-out transform hover:bg-white hover:text-black  active:opacity-70">
            {" "}
            {data.common_cta_button_text}
          </span>{" "}
        </NavLink>
      </div>

      <hr className="m-0  bg-[#252525] h-[1px] border-0" />
      <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2   gap-2 md:py-16 py-10 w-[85%] mx-auto">
        <div className="mt-3">
          <img src={data.logo_url} alt="" className="mb-3 w-[200px] h-[65px]" />
          {/* <p className="text-sm">{data.footer_text}</p> */}
        </div>
        <div className="mt-3">
          <h2 className="font-bold mb-2 uppercase text-sm">Products</h2>
          <ul className="text-white text-sm">
            {product?.map((pro)=>(
              <li className="mb-2 ">
              <NavLink to={`/division/${pro.short_url}`}>
                <span className="hover:underline uppercase"> {pro.title}</span>
              </NavLink>
            </li>
            ))}
            
             
          </ul>
        </div>
        <div className="mt-3">
          <h2 className="font-bold mb-2 uppercase text-sm">quick links</h2>
          <ul className="text-white text-sm">
           
             
            <li className="mb-2">
              <NavLink to="/contact-us">
                <span className="hover:underline uppercase">contact us</span>
              </NavLink>
            </li>
            <li className="mb-2">
              <NavLink to="/photo-gallery">
                <span className="hover:underline uppercase">photo gallery</span>
              </NavLink>
            </li>

            <li className="mb-2">
              <NavLink to="/video-gallery">
                <span className="hover:underline uppercase">video gallery</span>
              </NavLink>
            </li>
            <li className="mb-2">
              <NavLink to="/career">
                <span className="hover:underline uppercase">careers</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="mt-3">
          {(data.whatsapp_url || data.instagram_url || data.facebook_url || data.twitter_url || data.youtube_url) && (
          <div className="flex items-start   justify-between pb-4 text-xl text-white mb-6 ">
            {data.whatsapp_url && (
                <a
              href={`https://wa.me/${data.whatsapp_url}`}
              target="_blank"
              className="cursor-pointer"
            >
              <FaWhatsapp />
            </a>
            )}
           {data.instagram_url&& (
            <a
              href={data.instagram_url}
              target="_blank"
              className="cursor-pointer"
            >
              {" "}
              <FaInstagram />
            </a>)}
            {data.facebook_url && (
            <a
              href={data.facebook_url}
              target="_blank"
              className="cursor-pointer"
            >
              {" "}
              <FaFacebook />
            </a>)}
            {data.twitter_url && (
            <a
              href={data.twitter_url}
              target="_blank"
              className="cursor-pointer"
            >
              {" "}
              <FaXTwitter />
            </a>)}
            {data.youtube_url && (
            <a
              href={data.youtube_url}
              target="_blank"
              className="cursor-pointer"
            >
              {" "}
              <FaYoutube />
            </a>)}
          </div>
) }
          <div  >
            <div className="flex items-center">
              <FaEnvelope />{" "}
              <p className="ml-3">
                <a href={`mailto:${data.email_id}`}>{data.email_id}</a>{" "}
              </p>
            </div>
            <div className="flex items-center mt-3">
              <FaLocationDot /> <p className="w-fit ml-3">{address}</p>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-0  bg-[#252525] h-[1px] border-0" />
      <div className="md:flex justify-between items-center w-[85%] mx-auto py-6">
        <div className="md:flex items-center">
          <p className="text-sm mr-14">{data.copyright_text}</p>
          <div className="flex items-center my-4 md:my-0 text-gray-500">
            <NavLink to="/privacy-policy">Privacy policy</NavLink>
            <p className=" mx-2">|</p>
            <NavLink to="/terms-and-conditions">Terms and condition</NavLink>
          </div>
        </div>
        <div className="flex space-x-4">{data.designed_by_text}</div>
      </div>
    </footer>
  );
};

export default Footer;
