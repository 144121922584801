import React, { useState } from "react";
import {
  FaEnvelope,
  FaFacebook,
  FaPhone,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Required"),
});
const TopNav = ({ data, urls ,tag}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    setTimeout(() => setIsTransitioning(true), 10);
  };

  const closeModal = () => {
    setIsTransitioning(false);
    setTimeout(() => setIsModalOpen(false), 300);
  };

  const handleSubmit = (values) => {
    // Handle form submission logic here
    console.log(values);
    closeModal(); // Close modal after submission
  };

  return (
    <header className="relative bg-white">
       <div
        dangerouslySetInnerHTML={{
          __html: tag?.footer_tag,
        }}
      />
      <div className="md:flex items-center justify-between w-[94%] mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-center py-2 text-red">
          <div className="flex items-center">
            <FaPhone />
            <a href={`tel:${data?.phone_number}`} className="ml-2 mr-6 text-red">
              {data?.phone_number}
            </a>
          </div>
          <div className="flex items-center pt-2 md:pt-0">
            <FaEnvelope />
            <a href={`mailto:${data?.email_id}`} className="ml-2 mr-6">
              {data?.email_id}
            </a>
          </div>
        </div>
        <div className="flex items-center justify-center text-lg space-x-4 py-2 text-red">
          {urls?.facebook_url && (
          <a
            href={urls?.facebook_url}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer"
          >
            <FaFacebook />
          </a>)}
          {urls?.twitter_url && (
          <a
            href={urls?.twitter_url}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer"
          >
            <FaXTwitter />
          </a>)}
          {urls?.youtube_url && (
          <a
            href={urls?.youtube_url}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer"
          >
            <FaYoutube />
          </a>)}
          <NavLink onClick={openModal}>
            <span className="hover:text-red text-sm py-1 md:block">
              Corporate web login
            </span>
          </NavLink>
          <NavLink to="/career">
            <span className="hover:text-red text-sm py-1 hidden lg:block">
              Careers
            </span>
          </NavLink>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          className={`fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-[999] transition-opacity duration-300 ${
            isTransitioning ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className={`relative bg-white p-10 rounded-lg max-w-lg md:w-full w-[80%] max-h-[80vh] overflow-y-auto transition-transform duration-300 ${
              isTransitioning ? "scale-100" : "scale-50"
            }`}
          >
            <button
              className="absolute top-2 right-2 text-black"
              onClick={closeModal}
            >
              <AiOutlineClose size={18} />
            </button>
            <div className="text-center">
              <h2 className="text-3xl font-semibold">Welcome</h2>
              <p className="my-2 pb-3">Login with credentials</p>
            </div>

            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium mb-1"
                  >
                    Email Address
                  </label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your email..."
                    className="block w-full border border-gray-300 p-2 rounded-md focus:outline-none"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red  text-xs mt-1"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium mb-1"
                  >
                    Password
                  </label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Enter your password..."
                    className="block w-full border border-gray-300 p-2 rounded-md focus:outline-none"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red  text-xs mt-1"
                  />
                </div>
                <button
                  type="submit"
                  className=" w-full text-center border my-4 border-black hover:border-red text-black hover:bg-red hover:text-white py-2 px-4 rounded-md transition-colors duration-300 ease-in-out transform active:scale-95 active:opacity-70 "
                >
                  Login
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      )}
    </header>
  );
};

export default TopNav;
