import React, { useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendFormData } from "../../Api/Api";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  mobileNumber: Yup.string().required("Mobile Number is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
  // attachment: Yup.mixed()
  // .test("fileSize", "File size must be less than 5MB", (value) => {
  //   if (!value) return true; // Allow empty value (not required)
  //   return value.size <= 5 * 1024 * 1024;
  // })
    // .test("fileType", "Unsupported file format. Upload PDF or DOCX", (value) =>
    //   value &&
    //   ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(value.type)
    // ),
});

const ContactForm = () => {
  const fileInputRef = useRef(null);

  return (
    <div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          mobileNumber: "",
          email: "",
          subject: "",
          message: "",
          attachment: null,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const formData = new FormData();
            for (const key in values) {
              formData.append(key, values[key]);
            }

            // Send the form data to the server
            const result = await sendFormData(formData);

            // Display success toast
            toast.success(result);

            // Reset the form
            resetForm();
            if (fileInputRef.current) {
              fileInputRef.current.value = ""; // Clear the file input field
            }
          } catch (error) {
            // Display error toast
            toast.error("Form submission failed!");
            console.error("Form submission error:", error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* First Name */}
              <div>
                <label htmlFor="firstName" className="block font-medium">
                  First Name <span className="text-rose-700">*</span>
                </label>
                <Field name="firstName" type="text" className="block w-full p-2 border border-gray-300 rounded focus:outline-none" />
                <ErrorMessage name="firstName" component="div" className="text-rose-700 text-sm" />
              </div>

              {/* Last Name */}
              <div>
                <label htmlFor="lastName" className="block font-medium">
                  Last Name <span className="text-rose-700">*</span>
                </label>
                <Field name="lastName" type="text" className="block w-full p-2 border border-gray-300 rounded focus:outline-none" />
                <ErrorMessage name="lastName" component="div" className="text-rose-700 text-sm" />
              </div>

              {/* Mobile Number */}
              <div>
                <label htmlFor="mobileNumber" className="block font-medium">
                  Mobile Number <span className="text-rose-700">*</span>
                </label>
                <Field name="mobileNumber" type="text" className="block w-full p-2 border border-gray-300 rounded focus:outline-none" />
                <ErrorMessage name="mobileNumber" component="div" className="text-rose-700 text-sm" />
              </div>

              {/* Email */}
              <div>
                <label htmlFor="email" className="block font-medium">
                  Email <span className="text-rose-700">*</span>
                </label>
                <Field name="email" type="email" className="block w-full p-2 border border-gray-300 rounded focus:outline-none" />
                <ErrorMessage name="email" component="div" className="text-rose-700 text-sm" />
              </div>

              {/* Subject */}
              <div>
                <label htmlFor="subject" className="block font-medium">
                  Subject <span className="text-rose-700">*</span>
                </label>
                <Field name="subject" type="text" className="block w-full p-2 border border-gray-300 rounded focus:outline-none" />
                <ErrorMessage name="subject" component="div" className="text-rose-700 text-sm" />
              </div>
            </div>

            {/* Message */}
            <div>
              <label htmlFor="message" className="block font-medium">
                State your Requirement <span className="text-rose-700">*</span>
              </label>
              <Field name="message" as="textarea" rows="4" className="block w-full p-2 border border-gray-300 rounded focus:outline-none" />
              <ErrorMessage name="message" component="div" className="text-rose-700 text-sm" />
            </div>

            {/* File Upload */}
            <div className="mt-4">
              <label htmlFor="attachment" className="block font-medium">
                Attachment
              </label>
              <input
                type="file"
                id="attachment"
                name="attachment"
                ref={fileInputRef}
                className="mt-1 block text-sm text-gray-500 file:cursor-pointer file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                onChange={(event) => setFieldValue("attachment", event.currentTarget.files[0])}
              />
              <ErrorMessage name="attachment" component="div" className="text-rose-700 text-sm mt-1" />
            </div>

            {/* Submit Button */}
            <div className="text-end mt-6">
              <button
                type="submit"
                disabled={isSubmitting}
                className="border border-red  hover:border-red  py-2 px-4 rounded-md text-sm font-medium transition duration-300 hover:bg-red  hover:text-white active:opacity-70"
              >
                {isSubmitting ? "Sending..." : "Send Message"}
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <ToastContainer />
    </div>
  );
};

export default ContactForm;
